import React from 'react';
import Navbar from './common/Navbar';
import Map from './map/Map';



function App () {
return (
    <div>
        <Navbar />
        <Map />
    </div>
    
);
}

export default App;