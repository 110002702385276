import React from 'react';
import './Map.css';
import mapboxgl from '!mapbox-gl';  // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoibWF0dGhld21vb3Jjcm9mdCIsImEiOiJja3RzeXNoOXUxbDZuMnJsNDRkMGg2d2J3In0.SLhnCaOSwS7H3pVjieVOWw';

class Map extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lng: -4.6,
            lat: 54.5,
            zoom: 5
        };
        
        this.mapContainer = React.createRef();
    }
    componentDidMount() {
        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
        container: this.mapContainer.current,
        style: 'mapbox://styles/matthewmoorcroft/cktsz86da5mnb18o0qwlggtsl',
        center: [lng, lat],
        zoom: zoom
        });

        // this.map.on('load', () => {
        //     this.map.addSource('price', {
        //         type: 'geojson',

        //     })
        // })
    }

    render() {
        return (
            <div className="map-container">
                
                <div ref={this.mapContainer} className="map" />
            </div>
            
        )
    }
}

export default Map;