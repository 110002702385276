import React from 'react';
import {Navbar, Container} from 'react-bootstrap';

class NavBar extends React.Component {

    render() {
        return (
            <div>
                <Navbar bg="light" expand="lg">
                <Container>
    <Navbar.Brand href="#home">Resan</Navbar.Brand>
    
  </Container>

                </Navbar>
            </div>
            )
    }

}

export default NavBar;